@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');


@font-face {
  font-family: 'gothamBlack';
  src: url('./assets/fonts/Gotham\ Black.otf');
}


:root {

  --golf: url('./assets/images/bg-golf.jpg');
  --philantrophy: url('./assets/images/bg-philantrophy.jpg');
  --home: url('./assets/images/bg-home.jpg');
  --tigers: url('./assets/images/bg-tigers.jpg');
  --adventure: url('./assets/images/bg-adventure.jpg');

  --pm-grey: #D9D9D9;
  --pm-dark-grey: #9B9B9B;
  --pm-nav-black: #070707;
  --pm-yellow: #FAA932;

  --pm-font-size-900: 86px;
  --pm-leading-900: 64px;
  --pm-font-size-800: 50px;
  --pm-leading-800: 45px;
  --pm-font-size-700: 40px;
  --pm-leading-700: 36px;
  --pm-font-size-600: 30px;
  --pm-leading-600: 26px;
  --pm-font-size-500: 24px;
  --pm-leading-500: 24px;
  --pm-font-size-400: 20px;
  --pm-leading-400: 20px;
  --pm-font-size-300: 16px;
  --pm-leading-300: 20px;
  --pm-font-size-200: 14px;
  --pm-leading-200: 18px;
  --pm-font-size-100: 12px;
  --pm-leading-100: 15px;
  --pm-font-size-75: 10px;
  --pm-leading-75: 12px;
  --pm-font-size-50: 8px;
  --pm-leading-50: 10px;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #faaa3237;
  /* background: var(--pm-grey); */
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--pm-yellow);
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--pm-nav-black);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 16px solid var(--pm-grey);
  border-top: 16px solid var(--pm-yellow);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}